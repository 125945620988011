import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons"
//import { faCircle } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronCircleRight,
  faChevronCircleLeft,
  faEnvelope,
  faPhoneAlt,
  faCircle,
  faPhoneVolume,
  faUserMd,
  faMicroscope,
  faGlasses,
  faHandHoldingMedical,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faLinkedin,
  faEnvelope,
  faChevronCircleRight,
  faChevronCircleLeft,
  faPhoneAlt,
  faCircle,
  faPhoneVolume,
  faUserMd,
  faMicroscope,
  faGlasses,
  faHandHoldingMedical,
  faMapMarkerAlt
)
