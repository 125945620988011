import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Menu = ({ id, displayMenu }) => (
  <div id={id} className={`navbar-menu ${displayMenu ? "is-active" : ""}`}>
    <div className="navbar-end">
      <Link
        className="navbar-item has-text-primary"
        to="/"
        title="Oftalmologo en Guatemala Dr Mario Gutierrez / Cirugía Ojos / Visión"
      >
        Inicio
      </Link>
      <Link
        className="navbar-item has-text-primary"
        to="/#aboutSection"
        title="Conoce mas sobre Dr Mario Gutierrez"
      >
        Acerca de mi
      </Link>
      <Link
        className="navbar-item has-text-primary"
        to="/#servicesSection"
        title="Servicios Medicos del Dr Mario Gutierrez"
      >
        Servicios médicos
      </Link>
      <Link
        className="navbar-item has-text-primary"
        to="/#contactusSection"
        title="Para mas información contacto Dr Mario Gutierrez"
      >
        Contactame
      </Link>
      <div className="bottom-mobile-nav is-hidden-desktop">
        <a
          className="navbar-item"
          href="mailto:retinaguatemala@gmail.com"
          title="Reserva en linea o mas información envianos un correo a retinaguatemala@gmail.com"
        >
          <span className="icon mr-2">
            <FontAwesomeIcon icon="envelope" />
          </span>
          <span className="text">retinaguatemala@gmail.com</span>
        </a>
        <a
          className="navbar-item"
          href="tel:+50232265174"
          data-content="+50232265174"
          data-type="phone"
          title="Para consultas llama a +50232265174"
        >
          <span className="icon mr-2">
            <FontAwesomeIcon icon="phone-alt" />
          </span>
          <span className="text">+502.3226.5174</span>
        </a>
        <a
          className="navbar-item"
          href="https://wa.me/50245808745"
          target="_blank"
          rel="noopener noreferrer"
          title="Has tu cita en whatsapp +50245808745"
        >
          <span className="icon mr-2">
            <FontAwesomeIcon icon={["fab", "whatsapp"]} />
          </span>
          <span className="text">+502.4580.8745</span>
        </a>
        <div className="navbar-item social-media">
          <a
            className="menu-item"
            href="https://www.facebook.com/DrMarioGutierrez/"
            target="_blank"
            rel="noopener noreferrer"
            title="Sigue a Dr Mario Gutierrez en facebook"
          >
            <span className="icon">
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </span>
          </a>
          <a
            className="menu-item"
            href="https://www.instagram.com/drmariogutierrezgt/"
            target="_blank"
            rel="noopener noreferrer"
            title="Dr Mario Gutierrez en instagram"
          >
            <span className="icon">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </span>
          </a>
          <a
            className="menu-item"
            href="https://www.linkedin.com/in/dr-mario-gutierrez-paz-61208914a/"
            target="_blank"
            rel="noopener noreferrer"
            title="Sigue a Dr Mario Gutierrez en linkedin"
          >
            <span className="icon">
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Menu
