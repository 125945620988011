import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Eyebrow = () => (
  <nav className="eyebrow-menu is-hidden-touch">
    <ul className="menu is-left">
      <li>
        <a
          className="menu-item"
          href="mailto:retinaguatemala@gmail.com"
          title="Reserva en linea o mas información envianos un correo a retinaguatemala@gmail.com"
        >
          <span className="icon">
            <FontAwesomeIcon icon="envelope" />
          </span>
          <span className="text">retinaguatemala@gmail.com</span>
        </a>
      </li>
      <li>
        <a
          className="menu-item"
          href="tel:+50232265174"
          data-content="+50232265174"
          data-type="phone"
          title="Para consultas llama a +50232265174"
        >
          <span className="icon">
            <FontAwesomeIcon icon="phone-alt" />
          </span>
          <span className="text">+502.3226.5174</span>
        </a>
      </li>
      <li>
        <a
          className="menu-item"
          href="https://wa.me/50245808745"
          target="_blank"
          rel="noopener noreferrer"
          title="Has tu cita en whatsapp +50245808745"
        >
          <span className="icon">
            <FontAwesomeIcon icon={["fab", "whatsapp"]} />
          </span>
          <span className="text">+502.4580.8745</span>
        </a>
      </li>
    </ul>
    <ul className="menu is-right">
      <li>
        <a
          className="menu-item"
          href="https://www.facebook.com/DrMarioGutierrez/"
          target="_blank"
          rel="noopener noreferrer"
          title="Sigue a Dr Mario Gutierrez en facebook"
        >
          <span className="icon">
            <FontAwesomeIcon icon={["fab", "facebook-f"]} />
          </span>
        </a>
      </li>
      <li>
        <a
          className="menu-item"
          href="https://www.instagram.com/drmariogutierrezgt/"
          target="_blank"
          rel="noopener noreferrer"
          title="Sigue a Dr Mario Gutierrez en instagram"
        >
          <span className="icon">
            <FontAwesomeIcon icon={["fab", "instagram"]} />
          </span>
        </a>
      </li>
      <li>
        <a
          className="menu-item"
          href="https://www.linkedin.com/in/dr-mario-gutierrez-paz-61208914a/"
          target="_blank"
          rel="noopener noreferrer"
          title="Sigue a Dr Mario Gutierrez en linkedin"
        >
          <span className="icon">
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </span>
        </a>
      </li>
    </ul>
  </nav>
)

export default Eyebrow
