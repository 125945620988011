import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logo from "../images/logo_icon.png"
import chilltepe from "../images/chilltepe.png"

const Footer = () => (
  <>
    <footer>
      <div className="footer-top">
        <div className="container section">
          <div className="columns is-vcentered">
            <div className="column is-7 is-vertical">
              <figure className="image is-96x96 footer-brand">
                <a
                  href="https://www.retinaguatemala.com.gt/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Centro de Retina y Macula Consultores Guatemala, es un centro de Alta Especialidad en el campo de la Oftalmología en Guatemala"
                >
                  <img src={logo} alt="Retina Consultores Guatemala Logo" />
                </a>
              </figure>
              <div className="is-size-3 content is-marginless is-medium">
                <p className="has-text-primary is-marginless">
                  <strong className="has-text-primary">
                    Dr. Mario Gutierrez
                  </strong>
                </p>
                <p className="is-size-6 has-small-space is-marginless">
                  Cirujano Oftalmólogo Sub-Especialidad Retina y{" "}
                  <br className="is-hidden-touch" />
                  Segmento Anterior
                </p>
                <p className="is-size-6">Retina Consultores Guatemala</p>
              </div>
              <ul className="menu is-right">
                <li>
                  <a
                    className="menu-item"
                    href="https://www.facebook.com/DrMarioGutierrez/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Sigue a Dr Mario Gutierrez en facebook"
                  >
                    <span className="icon is-bg-gradient">
                      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item separate"
                    href="https://www.instagram.com/drmariogutierrezgt/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Sigue a Dr Mario Gutierrez en instagram"
                  >
                    <span className="icon is-bg-gradient">
                      <FontAwesomeIcon icon={["fab", "instagram"]} />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item separate"
                    href="https://www.linkedin.com/in/dr-mario-gutierrez-paz-61208914a/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Sigue a Dr Mario Gutierrez en linkedin"
                  >
                    <span className="icon is-bg-gradient">
                      <FontAwesomeIcon icon={["fab", "linkedin"]} />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="column is-vertical">
              <div className="content">
                <p className="footer-item">
                  <a
                    className="footer-link"
                    href="tel:+50232265174"
                    data-content="+50232265174"
                    data-type="phone"
                    title="Para consultas llama a +50232265174"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon="phone-alt" />
                    </span>
                    <span className="text">+502.3226.5174</span>
                  </a>
                </p>
                <p className="footer-item">
                  <a
                    className="footer-link"
                    href="https://wa.me/50245808745"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Has tu cita en whatsapp +50245808745"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                    </span>
                    <span className="text">+502.4580.8745</span>
                  </a>
                </p>
                <p className="footer-item">
                  <a
                    className="footer-link"
                    href="mailto:retinaguatemala@gmail.com"
                    title="Reserva en linea o mas informacion envianos un correo a retinaguatemala@gmail.com"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                    <span className="text">retinaguatemala@gmail.com</span>
                  </a>
                </p>
                <p className="footer-item no-my">
                  <a
                    className="footer-link"
                    href="https://maps.google.com/maps?ll=14.585311,-90.519686&z=16&t=m&hl=en&gl=US&mapclient=embed&q=10%20calle%202-45%20zona%2014%20Edificio%20Clinicas%20Las%20Am%C3%A9ricas%2C%20Consultorio%201103%2C%20Nivel%2011"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Ubicados en 10 calle 3-01 zona 14 Edificio Artes Médicas Primer nivel, clínica 105"
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon="map-marker-alt" />
                    </span>
                    <span className="text">
                      10 calle 3-01 zona 14 Edificio Artes Médicas Primer nivel, clínica 105
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="poweredby-wrapper has-text-centered">
            <span className="has-text-white">
              © {new Date().getFullYear()} Powered by
              {` `}
            </span>
            <a
              className="has-text-white"
              href="https://chilltepe.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Chilltepe is a technology company based in Guatemala city"
            >
              <figure className="image is-48x48 chilltepe">
                <img src={chilltepe} alt="Chilltepe S.A." />
              </figure>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
