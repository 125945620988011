/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, metaImage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const image = metaImage ? `${site.siteMetadata.siteUrl}${metaImage}` : null

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: site.siteMetadata.keywords.join(","),
          },
          {
            property: `og:site_name`,
            content: `Dr. Mario Gutierrez`,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: `https://www.drmariogutierrez.com`,
          },
          {
            property: `og:image`,
            content: image,
          }
        ].concat(meta)}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://www.schema.org",
            "@type": "Optician",
            "name": "Dr. Mario Gutierrez",
            "url": "https://www.drmariogutierrez.com/",
            "image": "${image}",
            "sameAs": [
                "https://www.linkedin.com/in/dr-mario-gutierrez-paz-61208914a/",
                "https://www.instagram.com/drmariogutierrezgt/",
                "https://www.facebook.com/DrMarioGutierrez/"
            ],
            "description": "Dr Mario Gutiérrez, cirujano Oftalmologo, especialista Catarata, Retina, Córnea y Cirugía Refractiva en Guatemala, entrenamiento Mexico, Canadá.",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "10 calle 3-01 zona 14 Edificio Artes Médicas Primer nivel, clínica 105",
                "addressLocality": "Guatemala",
                "addressCountry": "Guatemala"
            },
            "hasMap": "https://maps.google.com/maps?ll=14.585311,-90.519686&z=16&t=m&hl=en&gl=US&mapclient=embed&q=10%20calle%202-45%20zona%2014%20Edificio%20Clinicas%20Las%20Am%C3%A9ricas%2C%20Consultorio%201103%2C%20Nivel%2011",
              "openingHours": "Mo 08:00-18:00 Tu 08:00-18:00 We 08:00-18:00 Th 08:00-18:00 Fr 08:00-18:00 Sa 08:00-13:00",
            "telephone": "+50232265174"
          }
          `}
        </script>
      </Helmet>
    </>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
