import React, { useState, useEffect } from "react"
import Menu from "./Menu"
import Eyebrow from "./Eyebrow"

import logo from "../../images/logo_icon.png"

const MENU_TARGET = "drmarioMenu"

const Header = () => {
  const [displayMenu, setDisplayMenu] = useState(false)
  const [showNavbar, setShowNavbar] = useState(true)
  const [scrollPos, setScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY

      if (currentScrollPos > scrollPos) {
        setShowNavbar(false)
      } else {
        setShowNavbar(true)
        setDisplayMenu(false)
      }
      setScrollPos(currentScrollPos)
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [showNavbar, scrollPos])

  return (
    <header className="main-header">
      <nav
        className={`navbar main-navbar is-fixed-top ${
          scrollPos === 0 ? "initial" : showNavbar ? "show" : "hide"
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a
              className="navbar-item"
              href="https://www.retinaguatemala.com.gt/"
              target="_blank"
              rel="noopener noreferrer"
              title="Centro de Retina y Macula Consultores Guatemala, es un centro de Alta Especialidad en el campo de la Oftalmología en Guatemala"
            >
              <img
                className="navbar-brand-logo"
                src={logo}
                alt="Dr. Mario Gutierrez, Cirujano Oftalmólogo Sub-Especialidad Retina y Segmento Anterior"
              />
            </a>
            <p className="is-size-6 has-small-space">
              <strong>Retina Consultores Guatemala</strong>
            </p>
            <button
              className={`navbar-burger burger ${
                displayMenu ? "is-active" : ""
              }`}
              aria-label="menu"
              aria-expanded={displayMenu}
              aria-haspopup="true"
              data-target={MENU_TARGET}
              onClick={() => setDisplayMenu(!displayMenu)}
              tabIndex={0}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="navbar-nav-wrapper">
            <Eyebrow />
            <Menu id={MENU_TARGET} displayMenu={displayMenu} />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
