/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./Header"
import Footer from "./Footer"
import "../style/index.scss"
import "../utils/fontawesome"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="has-navbar-fixed-top">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
